import React, { useState } from "react"
import Modal from "react-modal"
import { OrangeButton } from "./button-orange"

export default function DeliveryPopup(props) {

    const data = props.data.edges
    const popupInfo = data[0].node

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const style = {
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '20px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-40%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '620px',
            height: '90%'
        },
        overlay: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1000
        }
    }

    return (
        <div className="container">

            <button
                onClick={toggleModal}
                className="pl-1 text-gray-400 hover:text-gray-500 underline transition duration-150 ease-in-out">
                Delivery & Returns
            </button>

            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    style={style}
                    ariaHideApp={false}
                >
                    {isOpen ? (
                        <div className="text-center max-w-3xl mx-auto">
                            <div className="p-5">
                                {/*header*/}
                                <div className="pb-5 border-b border-solid border-blueGray-200">
                                    <p className="text-2xl font-semibold mx-auto">
                                        Delivery & Returns
                                    </p>
                                    <div className="relative">
                                        <button className="absolute -top-10 -right-5 text-xl font-bold hover:text-orange transition duration-150 ease-in-out" title="Close Pop-Up" alt="Close Pop-Up" onClick={toggleModal}>X</button>
                                    </div>
                                </div>

                                {/*body*/}
                                <div className="py-5">
                                    <p className="font-bold text-lg underline">Delivery</p>
                                    <div className="text-blueGray-500 text-lg leading-relaxed" dangerouslySetInnerHTML={{
                                        __html: popupInfo.delivery.childMarkdownRemark.html,
                                    }}>
                                    </div>
                                    <p className="pt-5 font-bold text-lg underline">Returns</p>
                                    <div className="text-blueGray-500 text-lg leading-relaxed" dangerouslySetInnerHTML={{
                                        __html: popupInfo.returns.childMarkdownRemark.html,
                                    }}>
                                    </div>

                                </div>
                                <p className="font-semibold pb-5" >If you have any questions regarding our Delivery & Return Policy, contact us for more details!</p>

                                {/*footer*/}
                                <div className="border-t border-solid border-blueGray-200 pt-5">
                                    <OrangeButton buttonPage="/contact" buttonText="Contact Us" buttonTitle='Contact Us' buttonAlt='Iron West Group - Contact Us' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </div >
        </div >
    );
}