import React, { Component } from "react"

export class ProductInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            details: props.details,
            features: props.features,
        };
    }

    render() {
        const { details, features } = this.state;
        return (
            <div className="container mx-auto">

                <div className="px-5">
                    <div className="border border-gray-300" />
                    <div className="py-10 grid lg:grid-cols-2 gap-y-12 lg:gap-x-10 xl:gap-x-16">

                        <div className="">
                            <p className="font-bold text-3xl text-orange">Equipment Details</p>
                            <div className="pt-3 md:text-lg">
                                <StyleDetails details={details} />
                            </div>
                        </div>

                        <div className="">
                            <p className="font-bold text-3xl text-orange">Features</p>
                            <div className="pt-3 md:text-lg">
                                <StyleDetails details={features} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function StyleDetails(props) {
    var lines = []
    if (props.details) {
        lines = props.details.split("\n")
    }

    return (
        <div>
            {lines && lines.map((line, index) => {

                var category = line.substring(0, line.indexOf(":") + 1)
                var value = line.substring(line.indexOf(":") + 1)

                return (
                    <div key={index} className={`${category ? "grid grid-cols-2 gap-x-3" : ""} px-2 py-2 ${index % 2 === 0 ? "bg-white" : ""}  `}>

                        <p className="font-bold">
                            {category}
                        </p>

                        <p>
                            {value}
                        </p>
                    </div>
                )
            })}
        </div>
    )
}