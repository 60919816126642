import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Lock from "../icons/lock"

export class ProductInquiryForm extends Component {
    render() {
        return (
            <div>
                <p className="text-center text-2xl pb-3">Equipment Inquiry</p>
                <GatsbyImage className="max-w-md" image={this.props.image ? this.props.image.gatsbyImageData : null} loading="eager" title={this.props.title} alt={this.props.title} />

                <form
                    name={this.props.formName}
                    method="POST"
                    data-netlify="true"
                    action="/success"
                    className="sm:px-14 bg-white mx-auto"
                >
                    <input type="hidden" name="form-name" value={this.props.formName} />

                    <p className="text-center text-xl font-bold mt-2 pt-4" id="product-inquiry" name="product inquiry">{this.props.title}</p>

                    <hr className="my-6 border border-gray-200 " />

                    <div className="text-left flex flex-wrap -mx-2 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-first-name">First Name
                                <span className="text-orange">*</span>
                                <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="grid-first-name" type="text" name="first name" required placeholder="Jane" />
                            </label>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-last-name">Last Name
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-orange" id="grid-last-name" type="text" name="last name" placeholder="Doe" />
                            </label>
                        </div>
                    </div>
                    <div className="text-left flex flex-wrap -mx-2 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="company-name">Company Name
                                <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="company-name" type="text" name="company" placeholder="Iron West Group" />
                            </label>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="phone">Phone
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-orange" id="phone" name="phone" type="tel" placeholder="123-456-7890" />
                            </label>
                        </div>
                    </div>
                    <div className="text-left flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" required htmlFor="email">E-mail
                                <span className="text-orange">*</span>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="email" type="email" name="email" required placeholder="JaneDoe@mail.com" />
                            </label>
                        </div>
                    </div>

                    <div className="text-left -mx-3 mb-6">
                        <div className="px-3">
                            <label className="text-gray-700 text-sm font-bold" htmlFor="product inquiry">Product Inquiry
                                <textarea defaultValue={this.props.title} className="bg-white no-resize appearance-none w-full text-gray-700 leading-tight resize-none font-normal" id="product inquiry" name="product inquiry"></textarea>
                            </label>
                        </div>
                    </div>

                    <div className="text-left flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="message">How can we help you?
                                <span className="text-orange">*</span>
                                <textarea className="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange h-48 resize-none" id="message" name="message" required placeholder="i.e. Questions, Comments, etc...">
                                </textarea>
                            </label>
                            <p className="text-xs italic text-orange">*Required</p>
                        </div>
                    </div>

                    <div className="flex text-sm items-center text-gray-300 justify-center text-center">
                        <Lock />
                        <span className="pl-2">
                            We Value Your Privacy
                        </span>
                    </div>

                    <div className="flex justify-center pt-5 pb-3 mx-auto">
                        <button type="submit" title='Send Message To Iron West Group' alt='Send Message To Iron West Group' className="shadow bg-orange border hover:shadow-lg hover:shadow-orange hover:border-orange focus:shadow-outline focus:outline-none text-white font-semibold py-2 px-4 rounded-xl w-48 transition duration-150 ease-in-out">Send Message</button>
                    </div>
                </form>
            </div>
        )
    }
}