import React from "react"
import { ProductImageGallery } from "./product-image-gallery"
import DeliveryPopup from "./popup-delivery"
import ProductInquiryPopup from "./popup-product-inquiry"
import { graphql, useStaticQuery } from "gatsby"

const ProductMainSection = (productPage) => {
    var {
        images,
        category,
        title,
        price,
        body,
        videoUrl,
    } = productPage

    const data = useStaticQuery(graphql`
    query {
      allContentfulDeliveryReturnsPopUpOnProductPage {
            edges{
                node{
                    delivery {
                        childMarkdownRemark {
                            html
                          }
                    }
                    returns {
                        childMarkdownRemark {
                            html
                          }
                    }
                }
            }
        },
      }`)

    return (
        <div className="container mx-auto">
            <div className="px-5 py-5 lg:py-10 grid lg:grid-cols-2 gap-y-5 lg:gap-x-10 xl:gap-x-16">

                <div className="max-w-xs mx-auto md:max-w-xl lg:mx-0 xl:max-w-4xl">
                    <ProductImageGallery imagesGraph={images} videoUrl={videoUrl} title={title} />
                </div>

                <div className="text-center lg:text-left">
                    <p className="text-orange font-medium text-lg uppercase">{category}</p>
                    <h1 className="font-bold text-3xl md:text-4xl">{title}</h1>

                    <div className="lg:flex items-center py-3 gap-x-3">
                        <p className="text-orange text-3xl font-semibold">{price}</p>
                        <DeliveryPopup data={data.allContentfulDeliveryReturnsPopUpOnProductPage} />
                    </div>

                    <div className="md:text-lg mx-auto lg:mx-0" dangerouslySetInnerHTML={{
                        __html: body,
                    }}></div>

                    <div className="py-5">
                        <ProductInquiryPopup title={title} image={images ? images[0] : null} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductMainSection