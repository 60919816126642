import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Breadcrumb from '../components/breadcrumbs'
import { ProductInfo } from "../components/product-info"
import ProductMainSection from "../components/product-main-section"

class ProductPageTemplate extends React.Component {
    render() {
        const productPage = this.props.data.contentfulProduct

        return (
            <Layout>
                <Seo
                    title={productPage.title + ' - Iron West Group'}
                    keywords={[productPage.title, productPage.category, "Sacramento " + productPage.category + " For Sale", "Affordable Sacramento " + productPage.category + " For Sale", "Cheap Sacramento " + productPage.category + " For Sale"]}
                    location={"/inventory" + productPage.slug}
                    description={productPage.body.body}
                    isProductPage={true}
                    productName={productPage.title}
                    sku="10000001"
                    img={productPage.images[0].url}
                    productPage={productPage}
                />
                <div className="bg-lightgray">
                    <div className="pl-2 md:pl-4 pt-10 lg:pt-5">
                        <nav className="flex container mx-auto px-4 py-1 md:py-3" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/inventory', label: 'Inventory' },
                                { to: '/inventory' + productPage.slug, label: productPage.title }
                            ]} />
                        </nav>
                    </div>
                    <form
                        name="Product Inquiry"
                        method="POST"
                        data-netlify="true"
                        action="/success"
                        className="hidden"
                    >
                        <input type="hidden" name="form-name" value="Product Inquiry" />
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first-name">First Name:
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="grid-first-name" type="text" name="first name" required placeholder="Jane" />
                        </label>
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last-name">Last Name:
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-orange" id="grid-last-name" type="text" name="last name" placeholder="Doe" />
                        </label>
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company-name">Company Name:
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="company-name" type="text" name="company" placeholder="Iron West Group" />
                        </label>
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone:
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-orange" id="phone" name="phone" type="tel" placeholder="123-456-7890" />
                        </label>
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail:
                            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange" id="email" type="email" name="email" required placeholder="JaneDoe@mail.com" />
                        </label>
                        <label className="text-gray-700 text-sm font-bold" htmlFor="product inquiry">Product Inquiry:
                            <textarea className="bg-white no-resize appearance-none w-full text-gray-700 leading-tight resize-none" id="product inquiry" name="product inquiry"></textarea>
                        </label>
                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">How can we help you?
                            <textarea className="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-orange h-48 resize-none" id="message" name="message" required placeholder="i.e. Questions, Comments, etc..."></textarea>
                        </label>
                    </form >
                    <ProductMainSection images={productPage.images}
                        category={productPage.category}
                        title={productPage.title}
                        price={productPage.price}
                        body={productPage.body.childMarkdownRemark.html}
                        videoUrl={productPage.videoUrl}
                    />
                    <ProductInfo details={productPage.details && productPage.details.childMarkdownRemark ? productPage.details.childMarkdownRemark.rawMarkdownBody : ""}
                        features={productPage.features && productPage.features.childMarkdownRemark ? productPage.features.childMarkdownRemark.rawMarkdownBody : ""}
                    />
                </div>
            </Layout>
        )
    }
}
export default ProductPageTemplate
export const pageQuery = graphql`
query ContentfulProductBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulProduct( slug: {eq: $slug }) {
        slug
        title
        body {
            body
            childMarkdownRemark {
                rawMarkdownBody
                html
            }
        }
        category
        mileshours
        StockNumber
        price
        features {
            features
            childMarkdownRemark {
                rawMarkdownBody
                html
            }
        }
        details {
            details
            childMarkdownRemark {
                rawMarkdownBody
                html
            }
        }
        images {
            id
            description
            gatsbyImageData
            url
            title
        }
        videoUrl
    }
}
`